<template>
    <div class="container">
        <public-header />
        <router-view/>
    </div>
</template>

<script>
    import publicHeader from '@/components/common/PublicHeader'
    export default {
        components:{
            publicHeader,
        },
        created(){
            this.getNavs();
            this.getCompanyInfo();
        },
        methods:{
            //获取导航信息
            async getNavs(){
                if(!this.$store.getters.getNavsData){
                    try {
                        const {data} = await this.$axios({
                            url:'/api/firsttitle'
                        });
                        if(data.code){
                            this.$store.dispatch('setNavsData',data.data);
                        }
                    }catch (e) {
                        console.log(e);
                    }
                }
            },
            //获取公司信息
            async getCompanyInfo(){
                if(!this.$store.getters.getCompanyInfo){
                    try {
                        const {data} = await this.$axios('/api/companyprofile');
                        if(data.code){
                            this.$store.dispatch('setCompanyInfo',data.data[0]);
                        }
                    }catch (e) {
                        //
                    }
                }
            },
        },
        watch:{
            $route:{
                handler:function (val, oldVal) {
                    document.documentElement.scrollTop = 0;
                }
            },
        },
    }
</script>

<style>
    /*放公共css文件*/
    @import "./assets/css/main.css";
</style>