import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router);

const router = new Router({
    mode:'history',
    routes:[
        {
            path:'/',
            name:'index',
            meta:{
                title:'首页'
            },
            component:() => import('@/views/index')
        },
        {
            path:'/profile',
            name:'profile',
            meta:{
                title:'公司简介'
            },
            component:() => import('@/views/CompanyProfile')
        },
        {
            path:'/product',
            name:'product',
            meta:{
                title:'产品中心'
            },
            component:() => import('@/views/ProductCenter')
        },
        {
            path:'/solution',
            name:'solution',
            meta:{
                title:'解决方案'
            },
            component:() => import('@/views/Solution')
        },
        {
            path:'/news/:index?',
            name:'news',
            meta:{
                title:'新闻中心'
            },
            component:() => import('@/views/News')
        },
        {
            path:'/contact',
            name:'contact',
            meta:{
                title:'联系我们'
            },
            component:() => import('@/views/ContactUs')
        },
        {
            path:'/detail/:aid',
            name:'detail',
            meta:{
                title:'详情'
            },
            component:() => import('@/views/detail')
        },
    ]
});

export default router;