import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        navs:null,    //顶部菜单文件
        companyInfo:null,   //公司信息
    },
    mutations:{
        //设置导航菜单数据
        setNavsData(state,data){
            state.navs = data;
        },
        //设置公司信息
        setCompanyInfo(state,obj){
            state.companyInfo = obj;
        },
    },
    actions:{
        //设置导航菜单数据
        setNavsData({commit},data){
            commit('setNavsData',data);
        },
        //设置公司信息
        setCompanyInfo({commit},obj){
            commit('setCompanyInfo',obj);
        },
    },
    getters:{
        //获取导航菜单文件
        getNavsData(state){
            return state.navs;
        },
        //获取公司信息
        getCompanyInfo(state){
            return state.companyInfo;
        },
    },
})