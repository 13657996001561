import Vue from 'vue'
import Axios from 'axios'

/*哈工精密和国检计量版本不同之处：
    1、导航LOGO
    2、底部公司全称
    3、公司简介
    4、请求路径
    5、哈工的联系我们页面加入了友情链接，国检的没有
 */

const axios = Axios.create({
     // baseURL:'',
     baseURL:'https://www.hagonne.com',
     // baseURL:'http://www.ntit-test.com',
    // baseURL:'http://159.75.230.241:81',
});

//请求拦截
axios.interceptors.request.use(config => {
    return config;
});

//接收拦截
axios.interceptors.response.use(response => {
    return response;
});

axios.defaults.withCredentials = true;  //允许后端设置cookie
Vue.prototype.$axios = axios;

export default axios;