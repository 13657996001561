<template>
    <div class="header">
        <div class="header-wrap" @mouseleave="hideMenu">
            <div class="nav-first-box">
                <div class="header-logo">
                    <!--哈工精密-->
                    <img src="@/assets/imgs/logo1.png" alt="logo" />
                    <img src="@/assets/imgs/logo2.png" alt="logo" />

                    <!--国检计量-->
                    <!--<img src="@/assets/imgs/ntit-logo.png" style="width: 120px;" alt="logo" />-->
                    <!--<img src="@/assets/imgs/ntit-logo2.png" style="width: 110px;" alt="logo" />-->
                </div>
                <div class="header-nav">
                    <ul>
                        <li v-for="(item,index) in navs">
                            <router-link :to="item.url" @mouseenter.native="showSecondMenu(item,index)" :style="{borderBottomColor:index==firstActive?'#ffffff':'#000000'}">{{item.name}}</router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <!--二级菜单-->
            <div class="nav-second-box">
                <ul>
                    <li v-for="(item,index) in navs2" @mouseenter="showThirdMenu(item,index)" :style="{color:index==sceondActive?'#ffffff':'#999999'}" @click="goto(item,index)">
                        <span :style="{borderLeftColor:index==sceondActive?'#ffffff':'#999999'}">{{item.name}}</span>
                    </li>
                </ul>
            </div>
            <!--三级菜单-->
            <div class="nav-third-box">
                <!--<div class="types" v-if="navs3Type==1" :style="{paddingBottom:navs3.length>0?'20px':0}">-->
                <div class="types" :style="{paddingBottom:navs3.length>0?'20px':0}">
                    <ul>
                        <li v-for="item in navs3" @click="jump(item)">
                            <div>
                                <div class="div-pic">
                                    <img :src="'/images/website/'+item.image_url" alt="pic" />
                                </div>
                                <div class="div-title">{{item.name}}</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <!--代理分类-->
                <!--<div class="types2" v-else-if="navs3Type==2">-->
                    <!--<ul v-for="(item,index) in navs3">-->
                        <!--<li>-->
                            <!--<div>-->
                                <!--&lt;!&ndash;代理分类名称&ndash;&gt;-->
                                <!--<div class="div-title">{{item.title}}</div>-->
                                <!--<div class="div-container">-->
                                    <!--<div class="div-row" v-for="(it,ind) in item.arr2">-->
                                        <!--<div class="div-row-left">-->
                                            <!--<img src="@/assets/imgs/test.jpg" alt="pic" />-->
                                        <!--</div>-->
                                        <!--<div class="div-row-right">{{it.title}}</div>-->
                                    <!--</div>-->
                                <!--</div>-->
                            <!--</div>-->
                        <!--</li>-->
                    <!--</ul>-->
                <!--</div>-->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PublicHeader",
        data(){
            return{
                firstActive:null,
                navs:[],    //一级菜单
                navs2:[],   //二级菜单
                sceondActive:0,
                navs3:[],   //三级菜单
                // navs3Type:null, //三级菜单类型，代理为2，其他为1
            }
        },

        created(){
            this.getNavs();
        },


        methods:{
            //获取头部
            async getNavs(){
                if(this.$store.getters.getNavsData){
                    this.navs = this.$store.getters.getNavsData;
                }else{
                    try {
                        const {data} = await this.$axios({
                            url:'/api/firsttitle'
                        });
                        if(data.code){
                            const arr = data.data;
                            for(let i=0;i<arr.length;i++){
                                if(arr[i].id==3){
                                    if(arr[i].category){
                                        for(let j=0;j<arr[i].category.length;j++){
                                            if(arr[i].category[j].arr){
                                                for(let k=0;k<arr[i].category[j].arr.length;k++){
                                                    arr[i].category[j].arr[k].aid = arr[i].category[j].reference;
                                                }
                                            }
                                        }
                                    }
                                }else if(arr[i].id==4){
                                    if(arr[i].category){
                                        for(let j=0;j<arr[i].category.length;j++){
                                            if(arr[i].category[j].arr){
                                                for(let k=0;k<arr[i].category[j].arr.length;k++){
                                                    arr[i].category[j].arr[k].aid = arr[i].category[j].reference;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            this.navs = arr;
                            this.$store.dispatch('setNavsData',data.data);
                        }
                    }catch (e) {
                        console.log(e);
                    }
                }
            },
            //悬停一级显示二级菜单
            showSecondMenu(item,index){
                this.firstActive = index;   //设置一级菜单高亮显示
                if(item.category && item.category.length>0){
                    this.navs2 = item.category;
                    this.sceondActive = 0;
                    setTimeout(()=>{
                        document.querySelectorAll('.nav-second-box ul li').forEach(node => {
                            node.style.height = '50px';
                            node.style.transition = 'height .3s';
                        });

                        if(this.navs2[0].arr && this.navs2[0].arr.length>0){  //判断是否有3级菜单，有就显示
                            this.showThirdMenu(this.navs2[0],0);
                        }else{
                            this.navs3 = [];
                        }
                    },100);
                }else{
                    this.navs2 = [];
                    this.navs3 = [];
                }
            },
            //悬停二级显示三级菜单
            showThirdMenu(item,index){
                this.sceondActive = index;  //设置二级菜单高亮显示

                //如果是新闻，则返回,新闻不显示三级菜单。
                if(item.reference==5)return;

                document.querySelector('.nav-third-box').style.height = '0';
                if(item.arr&&item.arr.length>0){
                    // this.navs3Type = item.type;
                    this.navs3 = item.arr;
                    setTimeout(()=>{
                        document.querySelector('.nav-third-box').style.height = 'auto';
                        document.querySelector('.nav-third-box').style.transition = '.3s';
                    },150);
                }else{
                    this.navs3 = [];
                }
            },
            //离开事件
            hideMenu(){
                this.navs2 = [];
                this.navs3 = [];
                this.firstActive = null;
            },
            //点击跳转
            jump(item){
                this.$router.push({
                    name:'detail',
                    params:{aid:item.aid},
                    query:{
                        pid:item.reference,
                        id:item.id,
                    },
                });
                this.navs2 = [];
                this.navs3 = [];
                this.firstActive = null;
            },
            //点击2级菜单跳转新闻页面
            goto(item,index){
                if(item.reference == 5){    // 5是新闻id，跳转到新闻页面
                    this.$router.push({name:'news',params:{index:index+''}});
                }
            },
        },
    }
</script>

<style scoped>
    @font-face {
        font-family: 'test';
        src: url("../../assets/fonts/字魂35号-经典雅黑.ttf") format('truetype');
    }

    .header{
        width: 100%;
        height: 100px;
        background-color: rgba(0,0,0,1);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 199;
    }
    .header-wrap{
        margin: 0 auto;
        width: 1200px;
        /*overflow: hidden;*/
        font-family: test;
    }
    /*一级菜单*/
    .nav-first-box{
        overflow: hidden;
    }
    .nav-first-box .header-logo{
        width: 350px;
        float: left;
        line-height: 100px;
    }
    .nav-first-box .header-logo img:first-child{
        width: 70px;
        height: auto;
        vertical-align: middle;
        margin-right: 10px;
    }
    .nav-first-box .header-logo img:last-child{
        width: 100px;
        height: auto;
        vertical-align: middle;
    }
    .nav-first-box .header-nav{
        width: 850px;
        float: right;
    }
    .nav-first-box .header-nav ul{
        display: flex;
    }
    .nav-first-box .header-nav ul li{
        flex: 1;
        text-align: center;
        position: relative;
    }
    .nav-first-box .header-nav ul li a{
        color: #ffffff;
        line-height: 98px;
        display: inline-block;
        transition: .5s ease all;
        border-bottom: 2px solid #000000;
        font-size: 20px;
        letter-spacing: 2px;
    }

    /*二级菜单*/
    .nav-second-box{
        position: fixed;
        top: 100px;
        left: 0;
        width: 100%;
        background-color: #171717;
    }
    .nav-second-box ul{
        width: 1200px;
        margin: 0 auto;
        padding-left: 380px;
        overflow: hidden;
        box-sizing: border-box;
    }
    .nav-second-box ul li{
        float: left;
        color: #999;
        line-height: 50px;
        height: 0;
        padding-right: 60px;
        cursor: pointer;
        box-sizing: border-box;
    }
    .nav-second-box ul li:hover{}
    .nav-second-box ul li:hover span{}
    .nav-second-box ul li span{
        border-left: 2px solid #999;
        padding-left: 10px;
        letter-spacing: 2px;
    }

    /*三级菜单*/
    .nav-third-box{
        position: fixed;
        top: 150px;
        left: 0;
        width: 100%;
        background-color: rgba(34,34,34,1);
        height: 0;
        overflow: auto;
    }
    .nav-third-box .types{
        width: 1200px;
        margin: 0 auto;
        padding-left: 380px;
        box-sizing: border-box;
        overflow: hidden;
    }
    .nav-third-box .types ul{
        overflow: hidden;
    }
    .nav-third-box .types ul li{
        float: left;
        margin: 30px 0;
        border-right: 1px solid rgba(255,255,255,.1);
        cursor: pointer;
    }
    .nav-third-box .types ul li:hover div .div-title{
        color: #ffffff;
    }
    .nav-third-box .types ul li:first-child{
        padding-left: 0;
    }
    .nav-third-box .types ul li:nth-child(5){
        padding-left: 0;
    }
    .nav-third-box .types ul li:nth-child(4n){
        border-right: none;
    }
    .nav-third-box .types ul li>div{
        width: 200px;
    }
    .nav-third-box .types ul li>div .div-pic{
        text-align: center;
    }
    .nav-third-box .types ul li>div .div-pic img{
        width: 140px;
        height: 140px;
    }
    .nav-third-box .types ul li>div .div-title{
        color: #999999;
        text-align: center;
        transition: .5s ease all;
        letter-spacing: 2px;
    }

    /*代理分类*/
    .nav-third-box .types2{
        width: 1200px;
        margin: 0 auto;
        padding-left: 380px;
        box-sizing: border-box;
        overflow: hidden;
    }
    .nav-third-box .types2 ul{
        float: left;
        width: 200px;
    }
    .nav-third-box .types2 ul li{
        padding: 10px 5px;
        box-sizing: border-box;
        color: #999999;
        font-size: 12px;
        /*transition: .3s ease-in;*/
    }
    .nav-third-box .types2 ul li>div{
        border-right: 1px solid rgba(255,255,255,.1);
    }
    .nav-third-box .types2 ul li>div .div-title{
        color: #ffffff;
        line-height: 40px;
        font-size: 16px;
        font-weight: bold;
        text-indent: 10px;
    }
    .nav-third-box .types2 ul li>div .div-container{}
    .nav-third-box .types2 ul li>div .div-container .div-row{
        line-height: 28px;
        cursor: pointer;
    }
    .nav-third-box .types2 ul li>div .div-container .div-row:hover{
        color: #ffffff;
    }
    .nav-third-box .types2 ul li>div .div-container .div-row .div-row-left{
        display: none;
    }
    .nav-third-box .types2 ul li>div .div-container .div-row .div-row-left img{
        width: 30px;
        height: 30px;
    }
    .nav-third-box .types2 ul li>div .div-container .div-row .div-row-right{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: .3s ease-in;
    }
</style>